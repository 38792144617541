import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import Orders from "./app/features/orders/orders";
import { Route, Routes } from "react-router-dom";
import Layout from "./app/common/Layout";
import PageNotFound from "./app/common/PageNotFound";
import Configuration from "./app/features/configurations/Configuration";
import Login from "./app/authentications/Login";
import RequireAuth from "./app/authentications/RequireAuth";
import SpectionSection from "./app/features/configurations/spec-section";
import SpecTemplate from "./app/features/configurations/spec-template";
import SpecTemplateQuery from "./app/features/configurations/spec-template-query";
import Spec from "./app/features/specs/spec";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login></Login>} />
        <Route path="/" element={<Layout></Layout>}>
          <Route element={<RequireAuth />}>
            <Route path="/home" element={<Orders></Orders>} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route path="/spec" element={<Spec></Spec>} />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedUsers={[
                  "ashis-dc@crusadercaravans.com.au",
                  "nirav@crusadercaravans.com.au",
                  "rogy@crusadercaravans.com.au",
                  "arifa-dc@crusadercaravans.com.au",
                  "jovin@crusadercaravans.com.au",
                ]}
              />
            }
          >
            <Route path="/spec-sections" element={<SpectionSection />} />
            <Route path="/spec-template" element={<SpecTemplate />} />
            <Route
              path="/spec-template-query"
              element={<SpecTemplateQuery />}
            />
            {/* <Route
              path="/update-spec-template"
              element={<SpecTemplateUpdator />}
            /> */}
            <Route path="/configurations" element={<Configuration />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
}

export default App;
